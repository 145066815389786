import { 
  SET_USER_DETAILS
} from '../actions/actionTypes'

const initialState = {
  details:null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        details:{...action.details}
      };
    default:
      return state;
  }
} 

export default reducer