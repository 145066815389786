export const SET_USERS = "SET_USERS"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_COMPANIES = "SET_COMPANIES"
export const SET_COST_CENTERS = "SET_COST_CENTERS"
export const SET_REGIONS = "SET_REGIONS"
export const SET_SINDICATOS = "SET_SINDICATOS"
export const SET_DOCUMENTS = "SET_DOCUMENTS"
export const SET_SIGNEDIN_CHECKED = "SET_SIGNEDIN_CHECKED"
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN"
export const SET_RESET_PASSWORD = "SET_RESET_PASSWORD"
export const SET_CLEAN_AUTH = "SET_CLEAN_AUTH"
export const SET_CONFIGURACION_USUARIOS = "SET_CONFIGURACION_USUARIOS"
export const SET_REPORTS_SUMMARY = "SET_REPORTS_SUMMARY"
export const SET_REPORTS_USERS = "SET_REPORTS_USERS"
export const RESET_REPORTS_USER = "RESET_REPORTS_USERS"
export const RESET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS = "RESET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS"
export const SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS = "SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS"
export const RESET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS = "RESET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS"
export const SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS = "SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS"