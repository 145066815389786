import { 
  SET_REGIONS
} from '../actions/actionTypes'

const initialState = {
  regions:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REGIONS:
      return {
        regions: [...action.regions]
      };
    default:
      return state;
  }
} 

export default reducer