import { 
  SET_REPORTS_SUMMARY
} from '../actions/actionTypes'

const initialState = {
  activosSinContrato:0,
  terminadosSinFiniquito:0,
  sinDocumentos:0,
  menos6Liquidaciones:0,
  sinUltimaLiquidacion:0,
  sinLiquidaciones:0,
  sinVacaciones:0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REPORTS_SUMMARY:
      return {
        activosSinContrato: action.activosSinContrato,
        terminadosSinFiniquito: action.terminadosSinFiniquito,
        sinDocumentos: action.sinDocumentos,
        menos6Liquidaciones: action.menos6Liquidaciones,
        sinUltimaLiquidacion: action.sinUltimaLiquidacion,
        sinLiquidaciones: action.sinLiquidaciones,
        sinVacaciones: action.sinVacaciones,
      };
    default:
      return state;
  }
} 

export default reducer