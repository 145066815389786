import { combineReducers } from 'redux'
import UsersReducer from './UsersReducer'
import UserDetailsReducer from './UserDetailsReducer'
import CompaniesReducer from './CompaniesReducer'
import CostCentersReducer from './CostCentersReducer'
import RegionsReducer from './RegionsReducer'
import SindicatosReducer from './SindicatosReducer'
import DocumentsReducer from './DocumentsReducer'
import AuthReducer from './AuthReducer'
import ConfiguracionUsuariosReducer from './ConfiguracionUsuariosReducer'
import ReportsSummaryReducer from './ReportsSummaryReducer'
import ReportsReducer from './ReportsReducer'
import ReportePersonalizadoDocumentosReducer from './ReportePersonalizadoDocumentosReducer'
import ReportePersonalizadoLiquidacionesReducer from './ReportePersonalizadoLiquidacionesReducer'

export default combineReducers({
  auth:AuthReducer,
  users:UsersReducer,
  userDetails:UserDetailsReducer,
  companies:CompaniesReducer,
  costCenters:CostCentersReducer,
  regions:RegionsReducer,
  sindicatos:SindicatosReducer,
  documents:DocumentsReducer,
  configuracionUsuarios:ConfiguracionUsuariosReducer,
  reportsSummary:ReportsSummaryReducer,
  reports:ReportsReducer,
  reporteDocumentos:ReportePersonalizadoDocumentosReducer,
  reporteLiquidaciones:ReportePersonalizadoLiquidacionesReducer,
})