import { 
  SET_USERS
} from '../actions/actionTypes'

const initialState = {
  employees:[],
  totalPages:1,
  currentPage:1,
  TotalRecords:0,
	StartRecord:0,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        employees: [...action.employees],
        totalPages: action.totalPages,
        currentPage: action.currentPage,
        TotalRecords: action.TotalRecords,
        StartRecord: action.StartRecord,
      };
    default:
      return state;
  }
} 

export default reducer