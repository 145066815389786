import { API_ENDPOINT } from './APIEndpoint'
import { 
  SET_SIGNEDIN_CHECKED,
  SET_AUTH_TOKEN,
  SET_CLEAN_AUTH,
  SET_RESET_PASSWORD,
} from './actionTypes'

export const setSignedInChecked = () => async (dispatch) => {
  dispatch({type:SET_SIGNEDIN_CHECKED})
}

export const setAuthData = ({UserName,token,userType,nombre,apellido,canUploadDocuments}) => async (dispatch) => {
  dispatch({type:SET_AUTH_TOKEN,UserName,token,userType,nombre,apellido,canUploadDocuments})
}

export const signIn = ({UserName,Pass,OnError}) => async (dispatch) => {
  let result = await fetch(`${API_ENDPOINT}/auth/signIn`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({UserName,Pass})
  })
  let { status, token, userType, nombre, apellido, error } = await result.json()
  if(status===200){
    localStorage.setItem("jokertravel",JSON.stringify({UserName, token, userType, nombre, apellido }))
    dispatch(setAuthData({UserName, token, userType, nombre, apellido }))
  } else if(status===401){
    dispatch({type:SET_RESET_PASSWORD,UserName})
  } else if(status===404){
    OnError(error)
  }
}

export const signOut = () => async (dispatch) => {
  dispatch({type:SET_CLEAN_AUTH})
}

export const resetSignInCredentials = ({UserName,OldPass,NewPass,OnSuccess,OnError}) => async (dispatch) => {
  let result = await fetch(`${API_ENDPOINT}/auth/reset`,{
    method:'POST',
    headers: { "Content-Type":"application/json" },
    body: JSON.stringify({UserName,OldPass,NewPass})
  })
  let { status, error } = await result.json()
  if(status===200){
    OnSuccess()
  } else {
    OnError(error)
  }
}