import { 
  SET_DOCUMENTS
} from '../actions/actionTypes'

const initialState = {
  documents:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENTS:
      return {
        documents: [...action.documents]
      };
    default:
      return state;
  }
} 

export default reducer