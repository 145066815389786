import { 
  RESET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS,
  SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS
} from '../actions/actionTypes'

const initialState = {
  Estado:"",
  Condicion:null,
  Documentos:[],
  Empresas:[],
  CentrosCosto:[],
  Regiones:[],
  Sindicatos:[],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS:
      return initialState
    case SET_REPORTE_DOCUMENTOS_PERSONALIZADO_FILTERS:
      return {
        Estado: action.Estado,
        Condicion: action.Condicion,
        Documentos: [...action.Documentos],
        Empresas: [...action.Empresas],
        CentrosCosto: [...action.CentrosCosto],
        Regiones: [...action.Regiones],
        Sindicatos: [...action.Sindicatos]
      };
    default:
      return state;
  }
} 

export default reducer