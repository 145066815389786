import { 
  RESET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS,
  SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS
} from '../actions/actionTypes'

const initialState = {
  Estado:"",
  Condicion:null,
  NumeroLiquidaciones:null,
  Empresas:[],
  CentrosCosto:[],
  Regiones:[],
  Sindicatos:[],
  FechaTerminoSeleccionadoMonth:null,
  FechaTerminoSeleccionadoYear:null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS:
      return initialState
    case SET_REPORTE_LIQUIDACIONES_PERSONALIZADO_FILTERS:
      return {
        Estado: action.Estado,
        Condicion: action.Condicion,
        NumeroLiquidaciones: action.NumeroLiquidaciones,
        Empresas: [...action.Empresas],
        CentrosCosto: [...action.CentrosCosto],
        Regiones: [...action.Regiones],
        Sindicatos: [...action.Sindicatos],
        FechaTerminoSeleccionadoMonth:action.FechaTerminoSeleccionadoMonth,
        FechaTerminoSeleccionadoYear:action.FechaTerminoSeleccionadoYear,
      };
    default:
      return state;
  }
} 

export default reducer